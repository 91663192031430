<template>
  <div class="flix-form-group">
    <ul class="flix-nav">
      <li class="flix-html-li">
        <router-link class="flix-html-a" :to="{name: 'dashboardProfile', params: {id: getProfile()}}">
          <flixIcon :id="'user'" /> {{ $t('message.profile') }}
        </router-link>
      </li>
      <li class="flix-html-li">
        <router-link :to="{name: 'dashboardMembership'}" class="flix-html-a"><flixIcon :id='"crown"' /> {{ $tc('message.membership') }}</router-link>
      </li>
      <credits v-if="$store.getters.assistents.length && variables.user.first_name" />
      <li class="flix-html-li"><router-link :to="{ name: 'dashboardBackup', params: {} }" class="flix-html-a"><flixIcon :id="'random'" /> {{ $tc('message.backup', 2) }}</router-link></li>
      <li class="flix-html-li"><router-link :to="{ name: 'dashboardDataProcessingContract', params: {} }" class="flix-html-a"><flixIcon :id="'paragraph-alt'" /> {{ $tc('message.dataProcessingContract', 2) }}</router-link></li>
      <li class="flix-html-li"><router-link :to="{ name: 'dashboardDataCenter', params: {} }" class="flix-html-a"><flixIcon :id="'cloud'" /> {{ $tc('message.dataCenter', 2) }}</router-link></li>
      <li class="flix-html-li">
        <router-link class="flix-html-a" :to="{name: 'dashboardSMTP'}">
          <flixIcon :id="'message-out'" /> {{ $t('message.ownSMTP') }}
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  components: {
    credits () { return import('@/components/credits/menue') }
  },
  props: {},
  data () {
    return {
      variables: this.$getUserVariables()
    }
  },
  methods: {
    getProfile () {
      if (this.$route.name === 'dashboardProfile') {
        return this.$route.params.id
      }
      return 'profile'
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
  <style lang="sass" scoped>
    @import '@/assets/style/sass/stylesheet/flix_color_style.scss'
    .flix-nav
      display: flex
      align-items: center
      justify-content: flex-start
      gap: 5px
      flex-wrap: wrap
      padding-bottom: 20px
      border-bottom: 1px solid grey
      .flix-html-li
        font-size: 10pt
    .router-link-exact-active
      border-bottom: 2px solid $brand-primary
    @media (max-width: 500px)
      .flix-nav
        flex-direction: column
        justify-content: center
  </style>
